@import "~include-media/dist/include-media";
@import '../../../styles/common';

.homeBannerVideoContainer {
  position: relative;
  z-index: 1;

  .lightBoxVideoWrapper {
    position: absolute;
    left: 46.8%;
    transition: bottom 1s ease-in-out;

    @include media('<md') {
      bottom: 33%;
      left: 37%;
      z-index: 1;
    }

    @include media('>=md') {
      bottom: 28%;
      left: 40%;
    }

    @include media('>=lg') {
      bottom: 10%;
      left: 45.5%;
    }

    @media (min-width: map_get($breakpoints, lg)) {
      bottom: 27%;
    }

    @include media(">1180px") {
      bottom: 22%;
    }

    @media (min-width: map_get($breakpoints, xl)) {
      bottom: rem(legacy-rem(350));
    }

    @include media(">3000px") {
      bottom: rem(legacy-rem(850));
    }

  }
}
