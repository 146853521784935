@import "~include-media/dist/include-media";
@import '../../../styles/common';

@keyframes fade-in {
  to {
    opacity: 1;
    top: 0;
  }
}

.titleSubtitleWrapper {
  .title {
    position: relative;
    font-weight: $bold;
    color: $primary;
    font-family: $headerFont;
    margin-bottom: 0;

    @include load-animation(fade-in, 0.75s, ease-in-out, 1s) {
      opacity: 0;
      top: rem(20);
    }

    @include media ("<md") {
      font-size: rem(24);
    }
  }

  .subtitle {
    position: relative;
    font-size: rem(15);
    color: $secondary-darkgrey2;
    font-family: $bodyFont;
    padding-top: rem(20);

    @include load-animation(fade-in, 0.75s, ease-in-out, 1.5s) {
      opacity: 0;
      top: rem(20);
    }

    @include media(">=xl") {
      font-size: rem(legacy-rem(22));
      padding-top: rem(legacy-rem(40));
    }
  }
}
