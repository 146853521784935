@import 'src/styles/common';
@import "~include-media/dist/include-media";

.twoColCollapsableWrapper {
  position: relative;
  z-index: 1;

  @include media('<md') {
    margin-top: rem(legacy-rem(-300));
  }
  @include media('<sm') {
    margin-top: rem(legacy-rem(-320));
  }
}
.twoColCollapsableContainer {
  background: $white;
  padding-top: rem(legacy-rem(70));
  transition: margin-top 1s ease-in-out;

  @media (min-width: map_get($breakpoints, md)) {
    margin-top: rem(-138);
  }

  @media (min-width: map_get($breakpoints, lg)) {
    margin-top: rem(-260);
  }

  @include media(">1180px") {
    margin-top: rem(0);
  }

  @media (min-width: map_get($breakpoints, xl)) {
    & > div {
      margin-top: rem(legacy-rem(-300));
    }
  }

  @include media(">3000px") {
    & > div > div {
      margin-top: rem(legacy-rem(-500));
    }
  }

  .two-col-collapsable {
    z-index: 1;

    &__columns {
      display: flex;
      justify-content: space-between;

      @media (min-width: map_get($breakpoints, lg)) {
        padding-top: 0;
        padding-bottom: 0;
        padding-left: rem(legacy-rem(98));
        padding-right: rem(legacy-rem(98));
      }

      @media (min-width: map_get($breakpoints, xl)) {
        padding-left: rem(legacy-rem(182));
        padding-right: rem(legacy-rem(145));
      }

      transition: max-height 0.3s ease-in-out;
      mask-image: linear-gradient(to top, rgba(0,0,0,0), rgba(0,0,0,1), rgba(0,0,0,1), rgba(0,0,0,1));
      -webkit-mask-image: linear-gradient(to top, rgba(0,0,0,0), rgba(0,0,0,1), rgba(0,0,0,1), rgba(0,0,0,1));
      max-height: rem(legacy-rem(250));

      &--revealed {
        mask-image: none;
        -webkit-mask-image: none;
        // The slide open animation requires a max height that should not be reached by content hence 2000px
        max-height: unset;
        @include media("<md") {
          margin: 0;
          width: 100%;
          max-width: 100%;
        }
      }

      &-left {
        & > div {
          padding: 0;
        }

        @include media("<lg") {
          &::after {
            display: block;
            content: '';
            width: 100%;
            height: 2px;
            background: url("../../../public/images/dotted_line.svg");
            background-size: contain;
            margin-top: rem(20);
          }
        }

        p {
          color: $secondary-darkgrey2;
        }
      }

      &-left, &-right {
        flex-direction: column;

        @include media("<lg") {
          padding: 0;
        }
      }

      @include media(">=lg") {
        max-height: rem(legacy-rem(400));

        &--revealed {
          max-height: unset;
        }
      }

      @include media(">=lg") {
        max-height: rem(legacy-rem(630));
        width: 100%;
        margin: 0;

        &--revealed {
          max-height: unset;
        }

        &-left {
          padding-left: 0;
          padding-right: rem(legacy-rem(62));

          p {
            font-size: rem(legacy-rem(16));
            line-height: rem(legacy-rem(28));
          }
        }

        &-right {
          padding-left: rem(legacy-rem(44));
          padding-right: 0;

          p {
            font-size: rem(legacy-rem(16));
            line-height: rem(legacy-rem(28));
          }
        }
      }

      @include media(">=xl") {
        &-left {
          max-width: rem(490);
          padding: 0;

          p {
            font-size: rem(legacy-rem(18));
            line-height: rem(legacy-rem(36));
          }
        }

        &-right {
          padding: 0;
          max-width: rem(400);

          p {
            font-size: rem(legacy-rem(18));
            line-height: rem(legacy-rem(36));
          }
        }
      }
    }

    &__show-more {
      flex-direction: column;
      align-items: center;
      margin:auto;
      width: 100%;
      @include media("<md") {
        padding: 0;
      }

      @include media(">=md") {
        max-width: 50%;
      }
    }
  }
}

.showMoreWrapper,
.columnRightWrapper {
  @include media("<md") {
    width: 100%;
    max-width: 100%;
    padding: 0;
    margin: 0;
  }
}


.servicesListWrapper {
  .servicesTitle {
    display: block;
    font-size: rem(legacy-rem(22));
    margin-bottom: rem(legacy-rem(10));

    @include media(">=xl") {
      margin-left: rem(17);
      margin-bottom: rem(legacy-rem(15));
    }

    &.optional {
      display: none;

      @include media("<lg") {
        display: block;
      }
    }
  }

  &:first-of-type {
    ul {
      @include media(">=lg") {
        margin-left: 0 !important;
        margin-bottom: rem(legacy-rem(60)) !important;
      }

      li {
        margin-bottom: 0;
        align-items: center;

        p {
          @include media(">=lg") {
            @include custom-font-style($bodyFont, rem(legacy-rem(26)), $primary, bold, rem(legacy-rem(26)));
          }
        }

        span {
          text-decoration: none;
          display: block;

          a {
            text-decoration: none;
          }
        }
      }
    }
  }

  &:last-of-type {
    margin-top: rem(40);

    @include media(">=lg") {
      margin-top: rem(legacy-rem(80));
    }
  }

  ul {
    @include media(">=xl") {
      margin-left: rem(20);
    }

    li {
      margin-bottom: rem(5);
      @include media("<lg") {
        div {
          margin-right: 17px;
        }
      }
    }
  }
}

.servicesListWrapper:first-child {
  @media (min-width: map_get($breakpoints, lg)){
    margin-left: rem(legacy-rem(-9))
  }
}

.mobileServicesLists {
  .servicesListWrapper {
    ul {
      margin: 0 0 0 rem(legacy-rem(-9));
      padding-top: rem(legacy-rem(10));

      @media (max-width: map_get($breakpoints, lg)){
        @media (min-width: map_get($breakpoints, md)){
          display: grid;
          grid-template-columns: 1fr 1fr;
        }
      }

      li {
        span {
          a {
            text-decoration: underline;
            text-underline-position: under;
            text-underline-offset: -2.3px;

            p {
              font-size: rem(14);
              line-height: rem(22);
              font-weight: bold;
              color: $primary;
            }
          }
        }
      }
    }
  }
}

.accordionTitle {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  cursor: pointer;
  border-bottom: solid 1px $secondary-darkgrey2;
  padding: rem(legacy-rem(18)) 0;

  h3 {
    margin: 0;
    padding: 0;
  }
}

.accordionContent {
  margin-top: rem(legacy-rem(20));
  transition: all .3s ease-in;

  ul {
    margin: 0;
    padding: 0;
  }
}


.accordion {
  display: flex;
  flex-direction: row;
  cursor: pointer;
  border: none;
  background-color: transparent;
  font-size: rem(legacy-rem(22));
  line-height: rem(legacy-rem(28));
  font-weight: 400;

  &:focus {
    outline: none;
  }

  &__icon {
    margin-left: rem(legacy-rem(15));
    margin-top: rem(legacy-rem(8));
    height: rem(legacy-rem(14));
    width: rem(legacy-rem(14));

    &--minus {
      path {
        fill: $brand-pink;
        stroke: $brand-pink;

        &:nth-child(2) {
          display: none;
        }
      }
    }
  }
}
@media (max-width: map_get($breakpoints, lg)){
  .column {
    p {
      line-height: rem(24);
    }
  }
}
