@import "~include-media/dist/include-media";
@import '../../styles/common';

@keyframes fade-in {
  to {
    opacity: 1;
  }
}

.homeBannerWrapper {
  width: 100%;
  height: 400px;
  position: relative;

  @include load-animation(fade-in, 1.25s, ease-in-out, 0.5s) {
    opacity: 0;
  }

  @include media('<md') {
    height: 850px;
    top: -80px;
  }

  @media (min-width: map_get($breakpoints, md)) {
    height: 650px;
    top: -105px;
    background-size: 100%;
  }

  @media (min-width: map_get($breakpoints, lg)) {
    height: 835px;
    top: -215px;
  }

  @media (min-width: map_get($breakpoints, xl)) {
    height: 850px;
    top: -215px;
  }

  @include media(">=1281px") {
    height: 890px;
    top: -215px;
  }

  @media (min-width: map_get($breakpoints, xxl)) {
    height: 900px;
    top: -215px;
  }

  @include media(">=1441px") {
    height: 1050px;
    top: -215px;
  }

  @media (min-width: map_get($breakpoints, xxxl)) {
    height: 1050px;
    top: -215px;
  }

  @include media(">=1921px") {
    height: 1316px;
    top: -160px;
  }

  @media (min-width: map_get($breakpoints, xxxxl)) {
    height: 1316px;
    top: -160px;
  }

  @include media(">=2600px") {
    height: 1545px;
    top: -200px;
  }

  @include media(">3000px") {
    height: 1900px;
    top: -290px;
  }

  @media (min-width: map_get($breakpoints, xxxxxl)) {
    height: 1990px;
    top: -290px;
  }

  .image {
    object-fit: contain;
    transform: scale(2);

    @include media(">=md") {
      object-fit: cover;
      transform: scale(1);
    }
  }

  .navigation {
    width: 100%;
    height: 60px;
    border: 1px solid $black;
  }

  .mainContentWrapper {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;

    @keyframes content-fade-in {
      to {
        opacity: 1;
        height: 150px;

        @include media(">=md") {
          height: 500px;
        }
      }
    }

    .topCurvedWrapper {
      height: 800px;

      @include load-animation(content-fade-in, 0.2s) {
        opacity: 0;
      }
    }

    .topCurved {
      position: absolute;
      z-index: 1;
      background-image: url('../../../public/images/topCurved_bg_default.svg');
      background-position: bottom;
      background-size: 100%;
      background-repeat: no-repeat;
      width: 100%;
      overflow: hidden;

      --delta: 900px;
      --top-height: -220px;

      height: calc(var(--delta) - 370px);

      @include media(">460px") {
        height: calc(var(--delta) - 375px);
      }

      @include media(">640px") {
        height: calc(var(--delta) - 360px);
        --top-height: -180px;
      }

      @include media(">768px") {
        height: calc(510px);
        --top-height: -150px;
      }

      @include media(">1023px") {
        height: calc(960px);
        --top-height: -490px;
      }

      @include media(">1280px") {
        --top-height: -450px;
      }

      @include media(">1440px") {
        --top-height: -400px;
      }

      @include media(">1800px") {
        --top-height: -430px;
      }

      @include media(">1920px") {
        height: 60vw;
        --top-height: -550px;
      }

      @include media(">2100px") {
        height: 1250px;
      }

      @include media(">2560px") {
        height: 1435px;
        --top-height: -700px;
      }

      @include media(">2700px") {
        height: 1500px;
      }

      @include media(">3000px") {
        height: 57vw;
        --top-height: -1100px;
      }

      @include media(">3300px") {
        height: 56vw;
      }

      @keyframes top-fade-in {
        to {
          opacity: 1;
          top: var(--top-height);
        }
      }

      top: var(--top-height);

      @include load-animation(top-fade-in, 1s, cubic-bezier(.17, .3, .17, .98), 2s) {
        top: 0;
        opacity: 1;
      }

      @include media('<md') {
        width: 100% !important;
        background-image: url('../../../public/images/topCurved_bg_mobile.svg');
      }

      @media (min-width: 3000px) {
        background-image: url('../../../public/images/topCurved_bg_4k.svg');
      }
    }

    .titlesWrapper {
      position: fixed;
      margin: 50px auto 0;
      z-index: 99;
      top: 90px;

      @include media(">640px") {
        top: 120px;
      }

      @include media(">767px") {
        top: 150px;
      }

      @include media(">1023px") {
        top: 240px;
      }

      @include media(">1440px") {
        top: 200px;
      }

      @include media(">1640px") {
        top: 260px;
      }

      @include media(">1920px") {
        top: 250px;
      }

      @include media(">2560px") {
        top: 400px;
      }

      @media (min-width: map_get($breakpoints, md)) {
        max-width: 80%;
        left: 10%;
      }

      @media (min-width: map_get($breakpoints, lg)) {
        max-width: 70%;
        left: 15%;
      }

      @media (min-width: map_get($breakpoints, xl)) {
        max-width: 60%;
        left: 20%;
      }

      @media (min-width: map_get($breakpoints, xxxl)) {
        left: 22%;
      }

      @media (min-width: map_get($breakpoints, xxxxl)) {
        max-width: 40%;
        left: 30.75%;
      }

      @media (min-width: map_get($breakpoints, xxxxxl)) {
        left: 34%;
      }

      @include media('<md') {
        padding: 0 rem($grid-gutter-width-small * .5);
        max-width: 100%;
        left: 0;
      }
    }

    .bottomCurved {
      background-image: url('../../../public/images/bottomCurved_bg_mobile1.svg');
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      max-height: 800px;
      position: absolute;
      z-index: 0;
      width: 100%;

      --delta: 900px;
      --bottom-height: -135px;

      height: 425px;

      @include media(">768px") {
        --bottom-height: -210px;
        height: 382px;
      }

      @include media(">992px") {
        --bottom-height: -200px;
      }

      @include media(">1023px") {
        height: 425px;
      }

      @include media(">1024px") {
        --bottom-height: -300px;
        height: 500px;
      }

      @include media(">1180px") {
        --bottom-height: -330px;
      }

      @include media(">1250px") {
        --bottom-height: -340px;
      }

      @include media(">1280px") {
        height: 570px;
      }

      @include media(">1440px") {
        height: 655px;
      }

      @include media(">1920px") {
        --bottom-height: -300px;
        height: 780px;
      }

      @include media(">2560px") {
        --bottom-height: -400px;
        height: 1465px;
      }

      @include media(">3000px") {
        --bottom-height: -600px;
        height: 1365px;
      }

      @include media(">3300px") {
        height: 1390px;
      }

      @include media(">3500px") {
        height: 37vw;
      }

      @include media(">3840px") {
        --bottom-height: -700px;
      }

      @keyframes bottom-fade-in {
        to {
          opacity: 1;
          bottom: var(--bottom-height);
        }
      }

      bottom: var(--bottom-height);

      @include load-animation(bottom-fade-in, 1s, cubic-bezier(.17, .3, .17, .98), 2s) {
        bottom: 0;
        opacity: 1;
      }

      @media (max-width: 767px) {
        background-image: url('../../../public/images/bottomCurved_bg_mobile4.svg');
      }

      @media (max-width: 539px) {
        background-image: url('../../../public/images/bottomCurved_bg_mobile2.svg');
      }

      @media (min-width: map_get($breakpoints, md)) {
        background-image: url('../../../public/images/bottomCurved_bg_default.svg');
      }

      @media (min-width: map_get($breakpoints, xxxl)) {
        background-image: url('../../../public/images/bottom_curve_new.svg');
        background-size: cover;
      }

      @media (min-width: 3000px) {
        background-image: url('../../../public/images/bottomCurved_bg_4k.svg');
        background-size: cover;
        max-height: 1465px;
      }

      @media (min-width: map_get($breakpoints, xxxxxl)) {
        max-height: 1465px;
      }
    }
  }
}

.videoWrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;

  @include media('<md') {
    display: none;
  }
}
